import {CallbackSubscriber} from '../../event-aggregation-service/callback-subscriber';
import {IEventAggregationService} from '../../../interfaces/event-aggregation-service/event-aggregation-service.interface';
import {ISessionService} from '../../../interfaces/session-management/session-service.interface';
import { CatchReportAsyncException, CatchReportException } from "../decorators/error-decorator";
import {TokenBasedSession} from '../domain/entities/token-based-session';
import {CallbackSubscriberLegacy} from './callback-subscriber-legacy';
import {Deferred} from './classes/deferred';
import JwtAccessError from './classes/errors/jwt-access-error';
import {StudentapiDriver} from './classes/studentapi-driver';

export default class AuthController {

	private sessionService: ISessionService;
	private studentApiDriver: StudentapiDriver;
	private deferred: Deferred;
	private eventAggregationService: IEventAggregationService;
	private browserSessionStorage;
	private browserSessionStorageKey = 'LoggedInStatus';
	private subscribers: CallbackSubscriberLegacy[];

	private isLoggedInStatus = false;

	constructor(sessionService: ISessionService, studentApiDriver: StudentapiDriver, eventAggregationService: IEventAggregationService, browserSessionStorage) {

		this.sessionService = sessionService;
		this.studentApiDriver = studentApiDriver;
		this.eventAggregationService = eventAggregationService;
		this.browserSessionStorage = browserSessionStorage;
		this.deferred = new Deferred();
		this.subscribers = [];
	}

	@CatchReportException
	public initialize() {

		this.eventAggregationService.subscribeToSessionServiceReadyEvent(new CallbackSubscriber(() => this.onSessionServiceReady()));
		this.eventAggregationService.subscribeToSessionCreatedEvent(new CallbackSubscriber(() => this.onSessionCreated()));
		this.eventAggregationService.subscribeToSessionDestroyedEvent(new CallbackSubscriber(() => this.onSessionDestroyed()));

	}

	@CatchReportAsyncException
	private async onSessionServiceReady() {

		let session = await this.sessionService.getSession();

		this.setLoginStatus(session !== null);

		this.deferred.open();

	}

	@CatchReportException
	private onSessionCreated() {

		this.setLoginStatus(true);
		this.notifySubscribers();
	}

	@CatchReportException
	private onSessionDestroyed() {

		this.setLoginStatus(false);
		this.notifySubscribers();
	}

	/**
	 * @deprecated
	 */
	@CatchReportException
	private notifySubscribers() {

		this.subscribers.forEach((subscriber) => {
			subscriber.notify(null, {LoggedInStatus: this.isLoggedInStatus});
		});
	}

	/**
	 * @deprecated
	 * @param {(error, data) => void} callback
	 * @returns {Promise<void>}
	 */
	@CatchReportAsyncException
	public async subscribe(callback: (error, data) => void) {

		await this.deferred.untilOpen();

		let subscriber = new CallbackSubscriberLegacy(callback);

		subscriber.notify(null, {
			LoggedInStatus: this.isLoggedInStatus,
		});

		this.subscribers.push(subscriber);
	}

	/**
	 * @deprecated
	 * @returns {Promise<TokenBasedSession>}
	 */
	private async getTokenSession() {

		await this.deferred.untilOpen();
		let session = await this.sessionService.getSession() as TokenBasedSession;

		if (session === null) {
			throw new JwtAccessError('No session exists');
		}

		return session;
	}

	/**
	 * Send forgot password email
	 * @param email
	 * @returns {Promise<object>}
	 */
	@CatchReportAsyncException
	public async forgotPassword(email) {

		return this.studentApiDriver.resetPassword(email);
	}

	/**
	 * @deprecated
	 * @returns {Promise<boolean>}
	 */
	@CatchReportAsyncException
	public async sessionLogout() {

		await this.deferred.untilOpen();
		let session = await this.getTokenSession();

		return session.destroy();

	}

	/**
	 * @returns {Promise<boolean>}
	 */
	@CatchReportAsyncException
	public async subjectLogout() {

		await this.deferred.untilOpen();
		return this.sessionService.globalLogout();

	}

	/**
	 * @deprecated Will be fased out use sign method on the session
	 * @returns {Promise<string>}
	 */
	@CatchReportAsyncException
	public async getAccessToken() {

		await this.deferred.untilOpen();
		let session = await this.getTokenSession();

		return session.getAccessToken();
	}

	/**
	 * @deprecated Will be outfaced use user on Session
	 * @returns {Promise<string>}
	 */
	@CatchReportAsyncException
	public async getIdentityId() {

		await this.deferred.untilOpen();
		let session = await this.getTokenSession();

		return session.getUser().identityId;

	}

	/**
	 * @deprecated Will be outfaced use user on Session
	 * @returns {Promise<string>}
	 */
	@CatchReportAsyncException
	public async getEmail() {

		await this.deferred.untilOpen();
		let session = await this.getTokenSession();

		return session.getUser().email;

	}

	@CatchReportException
	public isLoggedIn() {

		return this.browserSessionStorage.getItem(this.browserSessionStorageKey) === 'true';
	}

	private setLoginStatus(status) {

		this.isLoggedInStatus = status;
		this.browserSessionStorage.setItem(this.browserSessionStorageKey, this.isLoggedInStatus);
	}

}

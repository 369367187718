import { Deferred } from '../../auth-controller/classes/deferred';
import { CatchReportAsyncException, CatchReportException } from "../../decorators/error-decorator";
import { Credentials } from './credentials';
import { RefreshTokenError } from '../errors/refresh-token-error';
import { ITokenService } from '../interfaces/token-service.interface';
import { CredentialsPayload } from "./credentials-payload";
import { Student } from './student';
import { ITokenBasedSession, IUser, IEventAggregationService } from '../../../../interfaces';
import { ServerTime } from '../../infrastructure/server-time/server-time';
import { IServerTime } from '../../infrastructure/server-time/server-time.interface';

export class TokenBasedSession implements ITokenBasedSession {

	private user: IUser = null;
	private credentials: Credentials = null;
	private tokenService: ITokenService;
	private eventAggregationService: IEventAggregationService;
	private deferred: Deferred = new Deferred();
	private serverTime: IServerTime = new ServerTime();
	private isUseablePromise: Promise<boolean> = null;

	constructor(tokenService: ITokenService, eventAggregationService: IEventAggregationService) {

		this.tokenService = tokenService;
		this.eventAggregationService = eventAggregationService;
		this.deferred.open();
	}

	@CatchReportAsyncException
	public async getAccessToken(): Promise<string> {

		await this.deferred.untilOpen();
		this.deferred.close();

		// No need to refresh
		if (!await this.shouldRefresh()) {
			this.deferred.open();
			return this.credentials.accessToken;
		}

		// Wait for the refresh to finish
		const credentials = await this.refreshCredentials();

		return credentials.accessToken;
	}

	@CatchReportAsyncException
	public async getRefreshToken(): Promise<string> {

		return this.credentials.refreshToken;
	}

	@CatchReportAsyncException
	public async destroy(): Promise<void> {
		try {
			const token = await this.getAccessToken();
			await this.tokenService.logoutSession(token);
		} finally {
			this.eventAggregationService.publishSessionDestroyedEvent();
		}
	}

	@CatchReportException
	public getUser(): IUser {

		return this.user;
	}

	@CatchReportAsyncException
	public async getCredentials(): Promise<Credentials> {

		return this.credentials;
	}

	@CatchReportAsyncException
	public setCredentials(credentials: Credentials) {
		if (!credentials.refreshToken) {

			this.eventAggregationService.publishSessionDestroyedEvent();
			throw new RefreshTokenError('Not a valid refresh token.');
		}
		if (credentials.accessToken !== null) {

			const tokenPayload = credentials.getPayload();
			this.setUser(tokenPayload);
		}

		this.credentials = credentials;
	}

	@CatchReportAsyncException
	public setUser(credentialsPayload: CredentialsPayload) {

		this.user = new Student(credentialsPayload.sub, credentialsPayload.email);
	}

	@CatchReportAsyncException
	public isUsable(): Promise<boolean> {
		if (this.isUseablePromise !== null) {
			return this.isUseablePromise;
		}

		this.isUseablePromise = new Promise(async (resolve, reject) => {

			if (!await this.shouldRefresh()) {
				return resolve(true);
			}

			try {
				await this.refreshCredentials();
			} catch {
				resolve(false);
			}

			resolve(true);
		});

		return this.isUseablePromise;
	}


	private async refreshCredentials() {
		try {

			// Get new credentials
			const newCredentials = await this.tokenService.refreshCredentials(this.credentials.refreshToken);
			// Update self
			this.setCredentials(newCredentials);
			// Notify for new credentials
			this.eventAggregationService.publishSessionRefreshedEvent();
			// Ready to refresh again
			this.deferred.open();
			// Return the access token
			return this.credentials;
		} catch (error) {

			this.deferred.open();

			if (error instanceof RefreshTokenError) {

				this.eventAggregationService.publishSessionDestroyedEvent();
			}

			throw error;
		}
	}

	private async shouldRefresh(): Promise<boolean> {
		return this.credentials === null || this.credentials.isExpired();
	}

}

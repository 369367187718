import { IEventAggregationService } from '../../../../interfaces/event-aggregation-service/event-aggregation-service.interface';
import { ITokenBasedSessionFactory } from '../interfaces/factory.interface';
import { TokenBasedSessionRepository } from '../repositories/token-based-session-repository';
import { ITokenBasedSessionRepository } from '../interfaces/token-based-session-repository.interface';

export class TokenBasedSessionRepositoryFactory {
	constructor(
		private eventAggregationService: IEventAggregationService,
		private tokenSessionFactory: ITokenBasedSessionFactory,
		private config: { hubUrl: string },
	) {

	}

	public createByBrowserNavigator(navigator: Navigator): ITokenBasedSessionRepository {
		const disableCrossDomain = this.isSafari(navigator);
		return new TokenBasedSessionRepository(this.eventAggregationService, this.tokenSessionFactory, this.config.hubUrl, disableCrossDomain);

	}

	private isSafari(navigator: Navigator): boolean {
		return /Safari/.test(navigator.userAgent) && (/Apple Computer/.test(navigator.vendor) || /CriOS/.test(navigator.userAgent));
	}
}

import { CallbackSubscriber } from './callback-subscriber';

export class OnceCallbackSubscriber<EventType> extends CallbackSubscriber<EventType> {

    private lastEventSent = '';

    constructor(callback: (event: EventType) => void) {
        super(callback);
    }

    notify(event: EventType): void {

        const serializedEvent = JSON.stringify(event);
        if (this.lastEventSent === serializedEvent) {

            return;
        }
        this.lastEventSent = serializedEvent;
        super.notify(event);
    }

}

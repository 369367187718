import {ISession} from '../../session-management/session.interface';
import { ITokenBasedSession } from '../../session-management';

export class SessionCreatedEvent {

	public readonly timestamp: Date;
	public readonly session: ITokenBasedSession;

	constructor(session: ITokenBasedSession) {

		this.timestamp = new Date();
		this.session = session;
	}
}

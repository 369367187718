import { DataStorage } from "@studyportals/data-storage";
import * as Cookies from "js-cookie";
import { ICredentials } from "../../../../../interfaces";
import { Credentials } from "../../entities/credentials";

export class LocalCredentialsStorage {
	private readonly sixMonthInSeconds = 15552000;
	private readonly cookieKey = 'SessionService-credentials';
	private readonly localStorageKey = 'SessionService/credentials';
	private cookies = Cookies;
	private localStorage = DataStorage;

	public getCredentials(): Credentials {
		const localStorageCredentials = this.getLocalStorageCredentials();
		const cookiesCredentials = this.getCookiesCredentials();

		if(localStorageCredentials && cookiesCredentials){
			if(localStorageCredentials.isNewerThan(cookiesCredentials)){
				return localStorageCredentials;
			}

			return cookiesCredentials;
		}

		if(localStorageCredentials) return localStorageCredentials;
		if(cookiesCredentials) return cookiesCredentials;
		return null;
	}

	public storeCredentials(credentials: ICredentials): void{
		const serializedCredentials = JSON.stringify(credentials);

		this.localStorage.store(this.localStorageKey, serializedCredentials, this.sixMonthInSeconds);
		this.cookies.set(this.cookieKey, serializedCredentials, {expires: this.sixMonthInSeconds});
	}

	public clearCredentials(): void {
		this.localStorage.remove(this.localStorageKey);
		this.cookies.remove(this.cookieKey);
	}

	private getCookiesCredentials(): Credentials {
		const rawCredentials = this.cookies.getJSON(this.cookieKey);

		if(rawCredentials === undefined){
			return null;
		}

		return Credentials.create(rawCredentials);
	}

	private getLocalStorageCredentials(): Credentials{
		const rawCredentials = this.localStorage.retrieve(this.localStorageKey);

		if(!rawCredentials){
			return null;
		}

		return Credentials.create(
			JSON.parse(rawCredentials)
		);
	}
}

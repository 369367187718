import {ISubscriber} from '../../interfaces/event-aggregation-service/subscriber.interface';

export class CallbackSubscriber<EventType> implements ISubscriber<EventType> {

    protected callback: (event: EventType) => void;
    constructor(callback: (event: EventType) => void) {

        this.callback = callback;
    }

    notify(event: EventType): void {

        try {
            this.callback(event);
        } catch (error) {
            console.error(error);
        }

    }

}

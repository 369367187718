import {RestCallType} from '../../../student/enums/restcall-type.enum';

export class StudentapiDriver {

    private baseUrl: string;
    private fetch: Function;

    constructor(baseUrl: string, fetch: Function) {

        this.baseUrl = baseUrl;
        this.fetch = fetch;
    }

    public resetPassword(email: string) {

        return this.sendRequest(RestCallType.POST, '/forgot-password', {Username: email});
    }

    public sendRequest(method: string, uri: string, data: object) {

        let options = {
            method,
            headers: {
                'Content-Type': 'application/json',
            },
        };

        if (method.toUpperCase() !== RestCallType.GET && data) {

            options['body'] = JSON.stringify(data);
        }

        return this.fetch(this.baseUrl + uri, options).then((response) => response.json());
    }

}

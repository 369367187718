import { DataStorage } from '@studyportals/data-storage';
import { CrossStorageClient } from 'cross-storage';
import { IEventAggregationService } from 'interfaces/event-aggregation-service/event-aggregation-service.interface';
import * as Cookies from "js-cookie";
import { ITokenBasedSession } from '../../../../interfaces';
import { CatchReportAsyncException } from "../../decorators/error-decorator";
import { InvalidAccessTokenError } from '../errors/invalid-access-token-error';
import { InvalidRefreshTokenError } from '../errors/invalid-refresh-token-error';
import { SessionStorageType } from '../enums/session-storage-type';
import { Credentials } from '../entities/credentials';
import { ITokenBasedSessionFactory } from '../interfaces/factory.interface';
import { LocalCredentialsStorage } from "./storage/local-credentials-storage";
import { SessionNotFoundError } from '../errors/session-not-found-error';
import { ITokenBasedSessionRepository } from '../interfaces/token-based-session-repository.interface';

export class TokenBasedSessionRepository implements ITokenBasedSessionRepository {

	// tslint:disable: typedef
	private readonly sixMonthInSeconds = 15552000;
	private readonly crossStorageKey = 'credentials';
	private localCredentialStorage = new LocalCredentialsStorage();
	private crossStorageClient: CrossStorageClient;
	private isCrossDomainStorageSuccessfullyInitialized: Promise<boolean> = null;

	constructor(
		private eventAggregationService: IEventAggregationService,
		private tokenSessionFactory: ITokenBasedSessionFactory,
		private hubURL: string,
		private isCrossDomainDisabled: boolean = false,
	) {

	}

	@CatchReportAsyncException
	public async initialize(): Promise<void> {
		this.isCrossDomainStorageSuccessfullyInitialized = this.initializeCrossDomainStorage();
		try {
			const localSession = await this.get(SessionStorageType.Local);
			await this.save(localSession);
		} catch (error) {
			if (await this.isCrossDomainStorageSuccessfullyInitialized) {
				await this.copySessionWhenExists();
			}
		}

		this.eventAggregationService.publishSessionRepositoryReadyEvent();
	}

	public async get(storageType: SessionStorageType = SessionStorageType.Local): Promise<ITokenBasedSession> {
		try {

			const credentials: Credentials = await this.getCredentials(storageType);
			if (credentials === null) {
				throw new SessionNotFoundError('There is no session in the session store');
			}

			return this.tokenSessionFactory.create(credentials);
		} catch (error) {

			if (error instanceof InvalidAccessTokenError ||
				error instanceof InvalidRefreshTokenError ||
				error instanceof SessionNotFoundError) {
				throw error;
			}

			throw new SessionNotFoundError(error.message, error);
		}
	}


	public async save(session: ITokenBasedSession): Promise<void> {
		const credentials = await session.getCredentials();
		this.localCredentialStorage.storeCredentials(credentials);

		if (await this.isCrossDomainStorageSuccessfullyInitialized) {
			await this.crossStorageClient.set(this.crossStorageKey, JSON.stringify(credentials), this.sixMonthInSeconds);
		}
	}


	public async clear(): Promise<void> {
		this.localCredentialStorage.clearCredentials();

		if (await this.isCrossDomainStorageSuccessfullyInitialized) {
			await this.crossStorageClient.del(this.crossStorageKey);
		}
	}

	private async copySessionWhenExists(): Promise<void> {
		try {
			const crossDomainSession = await this.get(SessionStorageType.CrossDomain);
			await this.localCredentialStorage.storeCredentials(await crossDomainSession.getCredentials());
		} catch (error) {

		}
	}

	private async initializeCrossDomainStorage(): Promise<boolean> {
		if (this.isCrossDomainDisabled) {
			return false;
		}

		try {
			this.crossStorageClient = this.getNewCrossStorageClient(this.hubURL);
			await this.crossStorageClient.onConnect();
			return true;
		} catch (error) {
			this.trackFallbackError();
			return false;
		}
	}

	private getNewCrossStorageClient(hubUrl: string): CrossStorageClient {

		return new CrossStorageClient(hubUrl, {
			timeout: 30000,
		});
	}

	private trackFallbackError(): void {
		if (typeof window['snowplow'] === 'undefined') {
			return null;
		}
		window['snowplow'](
			'trackStructEvent',
			'SessionService',
			'fallback',
			'localStorage',
		);
	}

	private async getCredentials(storageType: SessionStorageType): Promise<Credentials> {
		if (storageType === SessionStorageType.Local) {
			return this.localCredentialStorage.getCredentials();
		}

		return this.getCrossDomainCredentials();
	}

	private async getCrossDomainCredentials(): Promise<Credentials> {
		try {
			const credentials = await this.crossStorageClient.get(this.crossStorageKey);
			const serializedCredentials = JSON.parse(credentials);

			if (!serializedCredentials) {
				return null;
			}

			return Credentials.create(serializedCredentials);
		} catch (e) {
			return null;
		}
	}

}

import { IEventAggregationService } from '../../../../interfaces/event-aggregation-service';
import { ITokenBasedSessionService } from '../../../../interfaces/session-management';
import { ITokenBasedSession } from '../../../../interfaces/session-management';
import { CallbackSubscriber } from '../../../event-aggregation-service/callback-subscriber';
import { OnceCallbackSubscriber } from '../../../event-aggregation-service/once-callback-subscriber';
import { Deferred } from '../../auth-controller/classes/deferred';
import { CatchReportAsyncException, CatchReportException } from "../../decorators/error-decorator";
import { ITokenBasedSessionRepository } from '../interfaces/token-based-session-repository.interface';
import {RefreshTokenError} from '../errors/refresh-token-error';
import { ITokenService } from '../interfaces/token-service.interface';

export class TokenBasedSessionService implements ITokenBasedSessionService {

	public isReady = false;
	private tokenRepository: ITokenBasedSessionRepository;
	private eventAggregationService: IEventAggregationService;
	private tokenService: ITokenService;
	private session: ITokenBasedSession | Promise<ITokenBasedSession> = null;
	private deferred: Deferred;

	constructor(
		tokenRepository: ITokenBasedSessionRepository,
		eventAggregationService: IEventAggregationService,
		tokenService: ITokenService,
	) {

		this.tokenRepository = tokenRepository;
		this.eventAggregationService = eventAggregationService;
		this.tokenService = tokenService;
		this.deferred = new Deferred();
	}

	@CatchReportException
	public initialize(): void {

		this.eventAggregationService.subscribeToSessionRepositoryReadyEvent(this.onSessionRepositoryReady);
		this.eventAggregationService.subscribeToSessionDestroyedEvent(this.onSessionDestroyed);
		this.eventAggregationService.subscribeToSessionRefreshedEvent(this.onSessionRefreshed);
	}

	private get onSessionRepositoryReady() {

		return new CallbackSubscriber<void>(() => {
			this.openSessionService();
		});
	}

	private get onSessionRefreshed() {

		return new OnceCallbackSubscriber<void>(async () => {
			await this.saveRefreshedSession();
		});
	}

	private get onSessionDestroyed() {

		return new CallbackSubscriber<void>(
			async () => {

				await this.clearSession();
			}
		);
	}

	@CatchReportAsyncException
	public async getSession(): Promise<ITokenBasedSession> {

		try {

			if (this.session === null) {
				await this.loadSession();
			}

			return await this.session;
		} catch (error) {

			return null;
		}
	}

	@CatchReportAsyncException
	public async setSession(session: ITokenBasedSession): Promise<void> {

		try {
			// Wait until token repositories is ready
			this.deferred.untilOpen();

			this.session = session;
			// Check if the accessToken is valid, that would internally refresh it
			// and we can save the fresh session in the tokenRepository
			await session.getAccessToken();
			await this.tokenRepository.save(session as ITokenBasedSession);
			// Everything is fresh and saved, the session created event can be send
			this.eventAggregationService.publishSessionCreatedEvent(session);
		} catch (error) {

			this.session = null;
			// If there are any errors we need to make sure everyone reacts accordingly
			console.error(error);
			this.eventAggregationService.publishSessionDestroyedEvent();
		}

	}

	@CatchReportAsyncException
	public async globalLogout() {

		try {

			const session = await this.tokenRepository.get();
			const accessToken = await session.getAccessToken();

			await this.tokenService.logoutSubject(accessToken);

		} catch (error) {
			if (! (error instanceof RefreshTokenError)) {
				console.error(error);
			}
		}

		this.eventAggregationService.publishSessionDestroyedEvent();
	}

	@CatchReportException
	private openSessionService(){
		this.deferred.open();
		this.isReady = true;
		this.eventAggregationService.publishSessionServiceReadyEvent(this);
	}

	@CatchReportAsyncException
	private async saveRefreshedSession(){
		await this.tokenRepository.save(await this.session);
	}

	@CatchReportAsyncException
	private async clearSession(){
		this.session = null;
		await this.tokenRepository.clear();
	}

	private loadSession() {
		if (this.session !== null) {
			return;
		}

		this.session = new Promise(async (resolve, reject) => {
			try {
				const session = await this.tokenRepository.get();

				resolve(await session.isUsable() ? session : null);
			} catch (error) {
				resolve(null);
			}
		});
	}

}
